import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <h1>Welcome to my resumé!</h1>
    <embed src="images/resume.pdf" type="application/pdf" width="100%" height="600px" />
    <Link to="/site/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
